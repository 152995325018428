import React from "react";

export default function Colours({ section }) {
  const { colours = [] } = section;

  return (
    <div className="container">
      <div className="colour__list mb-10">
        {colours.map((c, i) => (
          <div className="colour__item" key={i}>
            <span className="colour__circle" style={{ backgroundColor: c.rgb }}></span>
            <span>{c.title}</span>
            <span>TSR: {c.tsr}%</span>
            <span>LRV: {c.lvr}%</span>
          </div>
        ))}
      </div>
    </div>
  );
}
