import React from "react";
import { Form, Field } from "react-final-form";

export default function Contact({ section }) {
  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const onSubmit = async (values) => {
    const hhEndpoint = `${process.env.REACT_APP_HH_URL}/sites/${process.env.REACT_APP_SITE_ID}/contact`;
    await postData(hhEndpoint, values);
  };

  const FormInputField = ({ name, label, type = "text" }) => {
    return (
      <Field name={name}>
        {({ input, meta }) => (
          <div className="col-md-6">
            <div className="form-floating mb-4">
              <input {...input} type={type} id={name} className={`form-control ${meta.error && meta.touched ? "is-invalid" : ""}`} />
              <label htmlFor={name}>{label}</label>
              {meta.error && meta.touched && <div className="invalid-feedback">{meta.error}</div>}
            </div>
          </div>
        )}
      </Field>
    );
  };

  return (
    <section className="wrapper bg-light">
      <div className="container pb-14 pb-md-16">
        <div className="row">
          <div className="col-xl-10 mx-auto">
            <div className="row gy-10 gx-lg-8 gx-xl-12">
              <div className="col-lg-8">
                <Form
                  onSubmit={onSubmit}
                  validate={(values) => {
                    const errors = {};
                    if (!values.name) {
                      errors.name = "Please enter your first name.";
                    }
                    if (!values.surname) {
                      errors.surname = "Please enter your last name.";
                    }
                    if (!values.email) {
                      errors.email = "Please provide a valid email address.";
                    }
                    if (!values.message) {
                      errors.message = "Please enter your messsage.";
                    }
                    return errors;
                  }}
                  render={({ handleSubmit, submitSucceeded }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row gx-4">
                        {submitSucceeded && (
                          <div className="col-12 mb-4">
                            <div className="alert alert-success" role="alert">
                              Thank you for your enquiry. We will get back to you shortly.
                            </div>
                          </div>
                        )}

                        <FormInputField name="name" label="first name *" type="text" />
                        <FormInputField name="surname" label="surname *" type="text" />
                        <FormInputField name="email" label="email *" type="email" />
                        <Field name="message">
                          {({ input, meta }) => (
                            <div className="col-12">
                              <div className="form-floating mb-4">
                                <textarea {...input} id="message" className={`form-control ${meta.error && meta.touched ? "is-invalid" : ""}`} style={{ height: "150px" }} />
                                {meta.error && meta.touched && <div className="invalid-feedback">{meta.error}</div>}
                                <label htmlFor="message">message</label>
                              </div>
                            </div>
                          )}
                        </Field>
                        <div className="col-12">
                          <input type="submit" className="btn btn-primary rounded-pill btn-send mb-3" value="Send message" disabled={submitSucceeded} />
                          <p className="text-muted">
                            <strong>*</strong> These fields are required.
                          </p>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </div>

              <div className="col-lg-4">
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon text-primary fs-28 me-4 mt-n1">
                      {" "}
                      <i className="uil uil-phone-volume"></i>{" "}
                    </div>
                  </div>
                  <div>
                    <h5 className="mb-1">Phone</h5>
                    <p>
                      Matt Hopkins <br />
                      <a href="tel:027 569 4741">027 569 4741</a>
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div>
                    <div className="icon text-primary fs-28 me-4 mt-n1">
                      {" "}
                      <i className="uil uil-envelope"></i>{" "}
                    </div>
                  </div>
                  <div>
                    <h5 className="mb-1">E-mail</h5>
                    <p className="mb-0">
                      <a href="mailto:info@manawaturoofing.co.nz" className="link-body">
                        info@manawaturoofing.co.nz
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
