import React from "react";

export default function BannerAlt3({ section }) {
  const { image = {}, paragraph1, title } = section;
  return (
    <>
      <section className="wrapper bg-soft-primary">
        <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
          <div className="row">
            <div className="ccol-md-10 col-lg-8 col-xl-7 mx-auto">
              <h1 className="display-1 mb-3">{title}</h1>
              <p className="lead mr-prewrap">{paragraph1}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container pb-14 pb-md-16">
          <div className="row">
            <div className="col-12">
              <article className="mt-n16">
                <figure className="rounded mb-8 mb-md-12">
                  <img src={image.url} alt="" />
                </figure>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
